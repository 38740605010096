var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('search-box',{attrs:{"label":"* Documento","popup-label":"Selecione um Documento","id":_vm.value.idDocumento,"descricao":_vm.value.descricaoDocumento,"hidden-clear":true,"value":_vm.documentos,"loader-fn":_vm.loaderDocumentos,"item-key":"id","item-text":"descricao","rules":[
            this.$validators.notNullOrUndefined
        ]},on:{"update:id":function($event){return _vm.$set(_vm.value, "idDocumento", $event)},"update:descricao":function($event){return _vm.$set(_vm.value, "descricaoDocumento", $event)}},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('p',{staticClass:"ma-2"},[_vm._v(" Nenhum Documento encontrado ")])]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('v-text-field',{attrs:{"label":"* Descrição","dense":"","rules":[
                      this.$validators.string.required,
                           function (v) { return this$1.$validators.string.greaterThanOrEquals(v, 1); },
                      function (v) { return this$1.$validators.string.lessThanOrEquals(v, 200); }
                  ]},model:{value:(_vm.value.descricao),callback:function ($$v) {_vm.$set(_vm.value, "descricao", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.descricao"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-text-field',{attrs:{"label":"* Ordem","dense":"","rules":[
            this.$validators.number.required,
            function (v) { return this$1.$validators.number.greaterThanOrEquals(v, 0); }
        ],"type":"number"},model:{value:(_vm.value.ordem),callback:function ($$v) {_vm.$set(_vm.value, "ordem", $$v)},expression:"value.ordem"}})],1),_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":"12","md":"6"}},[_c('v-switch',{attrs:{"true-value":'S',"false-value":'N',"label":"Os prompts são iguais?"},model:{value:(_vm.value.flgPromptsIguais),callback:function ($$v) {_vm.$set(_vm.value, "flgPromptsIguais", $$v)},expression:"value.flgPromptsIguais"}})],1),_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":"12","md":"6"}},[_c('v-switch',{attrs:{"true-value":'S',"false-value":'N',"label":"O prompt busca na IA?"},model:{value:(_vm.value.flgBuscaIa),callback:function ($$v) {_vm.$set(_vm.value, "flgBuscaIa", $$v)},expression:"value.flgBuscaIa"}})],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('OrientacoesPreenchimentoPrompt')],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.promptsIguais),expression:"promptsIguais"}],attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"dense":"","rows":"8","label":"Prompt","rules":[
            function (v) { return this$1.$validators.string.greaterThanOrEquals(v, 1); } ]},model:{value:(_vm.value.prompt),callback:function ($$v) {_vm.$set(_vm.value, "prompt", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.prompt"}})],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(!_vm.promptsIguais),expression:"!promptsIguais"}],attrs:{"cols":"12","md":"6"}},[_c('v-textarea',{attrs:{"dense":"","rows":"5","label":"Prompt de Material","rules":[
            function (v) { return this$1.$validators.string.greaterThanOrEquals(v, 1); } ]},model:{value:(_vm.value.promptMaterial),callback:function ($$v) {_vm.$set(_vm.value, "promptMaterial", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.promptMaterial"}})],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(!_vm.promptsIguais),expression:"!promptsIguais"}],attrs:{"cols":"12","md":"6"}},[_c('v-textarea',{attrs:{"dense":"","rows":"5","label":"Prompt de Serviço","rules":[
            function (v) { return this$1.$validators.string.greaterThanOrEquals(v, 1); } ]},model:{value:(_vm.value.promptServico),callback:function ($$v) {_vm.$set(_vm.value, "promptServico", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.promptServico"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }