<template>
  <v-col cols="12" class="text-caption">
      <v-divider/>
      <div>Ao escrever o(s) prompt(s), você poderá usar uma ou mais chaves abaixo e o sistema substituirá automaticamente pelos itens que compõem a contratação no momento de gerar os documentos.</div>
      <div>
        {qtd-itens} - quantidade de itens, {item} - Itens, {orgao} - Orgãos, {unidade} - Undades, {regiao} - Regiões, 
        {almoxarifado} - Almoxarifados, {unidadeMedida} - Unidades de Medida, {quantidade} - quantidades e {valorTotalPlanejado} - Valor Total Planejado
      </div>
      <v-divider/>
    </v-col>
</template>

<script>
export default {
  name: 'OrientacoesPreenchimentoPrompt',
  data() {
    return {
    }
  },
  mounted() {
  },
};
</script>

<style scoped>
</style>
