<template>
<v-row>
  <v-col cols="12" md="5">
    <search-box
        label="* Documento"
        popup-label="Selecione um Documento"
        :id.sync="value.idDocumento"
        :descricao.sync="value.descricaoDocumento"
        :hidden-clear="true"
        :value="documentos"
        :loader-fn="loaderDocumentos"
        item-key="id"
        item-text="descricao"
        :rules="[
            this.$validators.notNullOrUndefined
        ]">
      <template v-slot:empty>
        <p class="ma-2">
          Nenhum Documento encontrado
        </p>
      </template>
    </search-box>
  </v-col>
  <v-col cols="12" md="5">
    <v-text-field label="* Descrição"
                  dense
                  :rules="[
                      this.$validators.string.required,
                           v => this.$validators.string.greaterThanOrEquals(v, 1),
                      v => this.$validators.string.lessThanOrEquals(v, 200)
                  ]"
                  v-model.trim="value.descricao"/>
  </v-col>
  <v-col cols="12" md="2">
    <v-text-field
        label="* Ordem"
        dense
        :rules="[
            this.$validators.number.required,
            v => this.$validators.number.greaterThanOrEquals(v, 0)
        ]"
        v-model="value.ordem" type="number"/>                  
  </v-col>
  <v-col cols="12" md="6" class="ma-0 pa-0">
    <v-switch 
      v-model="value.flgPromptsIguais" 
      :true-value="'S'"
      :false-value="'N'"
      label="Os prompts são iguais?"></v-switch>
  </v-col>
  <v-col cols="12" md="6" class="ma-0 pa-0">
    <v-switch 
      v-model="value.flgBuscaIa" 
      :true-value="'S'"
      :false-value="'N'"      
      label="O prompt busca na IA?"></v-switch>
  </v-col>
  <v-col cols="12" md="12">
    <OrientacoesPreenchimentoPrompt/>
  </v-col>
  <v-col cols="12" v-show="promptsIguais">
    <v-textarea
        dense
        rows="8"
        label="Prompt"
        :rules="[
            v => this.$validators.string.greaterThanOrEquals(v, 1),
        ]"
        v-model.trim="value.prompt"/>         
  </v-col>
  <v-col cols="12" md="6" v-show="!promptsIguais">
    <v-textarea
        dense
        rows="5"
        label="Prompt de Material"
        :rules="[
            v => this.$validators.string.greaterThanOrEquals(v, 1),
        ]"
        v-model.trim="value.promptMaterial"/>         
  </v-col>
  <v-col cols="12" md="6" v-show="!promptsIguais">
    <v-textarea
        dense
        rows="5"
        label="Prompt de Serviço"
        :rules="[
            v => this.$validators.string.greaterThanOrEquals(v, 1),
        ]"
        v-model.trim="value.promptServico"/>      
  </v-col>
</v-row>
</template>

<script>
import {findAll as findAllDocumentos} from "../../../api/documento";
import LookupDominio from "../common/LookupDominio";
import OrientacoesPreenchimentoPrompt from "../common/OrientacoesPreenchimentoPrompt";
import SearchBox from "../common/SearchBox";

export default {
  name: "ParagrafoForm",
    components: {SearchBox, LookupDominio, OrientacoesPreenchimentoPrompt},
  props: {
    value: {
      type: Object
    }
  },
  data() {
    return {
      loading: false,
      error: null,
    }
  },
  computed: {
    documentos() {
      if (this.value.idDocumento == null) return null;
      return {
        id: this.value.idDocumento,
        descricao: this.value.descricaoDocumento
      }
    },
    promptsIguais() {
      return this.value.flgPromptsIguais == 'S'
    }
  },
  watch: {
    'value.prompt': {
      handler(newValue) {
        if (this.value.flgPromptsIguais == 'S') {
          this.value.promptServico = newValue;
          this.value.promptMaterial = newValue;
        }
      }
    },
    'value.flgPromptsIguais': {
      handler(newValue) {
        if ('S') {
          this.value.promptMaterial = this.value.prompt;
          this.value.promptServico = "";
        }
      }
    },
  },  
  methods: {
    loaderDocumentos(page, search) {
      return findAllDocumentos(page, {
        nome: search
      })
    },
  }
}
</script>

<style scoped>

</style>