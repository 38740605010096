<template>
  <v-autocomplete hide-details
                  ref="item"
                  :label="label"
                  hide-no-data
                  :value="value"
                  :rules="rules"
                  :loading="loading"
                  :disabled="loading"
                  return-object
                  @input="select"
                  @keyup="teste"
                  dense
                  @click:clear="resetValues"
                  :clearable="!hiddenClear"
                  :items="elementsWithValue"
                  :placeholder="label"
                  :item-text="itemText"
                  :item-value="itemKey">

    <template v-slot:no-data>
      <slot name="empty"/>
    </template>
    <template v-slot:append>
      <slot name="append"/>
      <span v-if="error">
        {{ error }}
        <v-icon color="error">mdi-alert</v-icon>
        </span>
    </template>
    <template v-slot:append-outer v-if="hiddenRefresh !== true">
      <v-tooltip top left>
        <template v-slot:activator="{on}">
          <v-btn v-on="on" icon @click="refresh">
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </template>
        <span>
          Clique aqui para buscar novamente os itens
        </span>
      </v-tooltip>
    </template>
  </v-autocomplete>
</template>

<script>

export default {
  name: "SearchBox",
  props: ['value', 'itemText', 'disabled', 'label', 'popupLabel', 'itemKey', 'loaderFn', 'rules', 'hiddenClear', 'hiddenRefresh', 'emitAllProperties'],
  data() {
    return {
      refreshDebounce: null,
      tap: 0,
      error: false,
      elements: [],
      page: 1,
      searchInput: '',
      loading: true
    }
  },
  computed: {
    itemKeyValue() {
      return this.value ? this.value[this.itemKey] : null;
    },
    itemDescValue() {
      return this.value ? this.value[this.itemText] : null;
    },
    elementsWithValue() {
      if(this.value) {
        return [
            this.value,
            ...this.elements.filter(e => e[this.itemKey] !== this.itemKeyValue)
        ]
      }
      return this.elements;
    }
  },
  methods: {

    resetValues() {
      this.$emit('input', null);
      this.$emit(`update:${this.itemKey}`, null);
      this.$emit(`update:${this.itemText}`, null);
      if (this.emitAllProperties && this.item != null) {
        Object.keys(this.item).forEach(key => {
          if (key !== this.itemKey && key !== this.itemText) {
            this.$emit(`update:${key}`, item[key]);
          }
        });
      }
    },

    teste(v) {
      if((v.keyCode >= 65 && v.keyCode <= 90) || (v.key === 'Backspace')) {
        clearTimeout(this.refreshDebounce);
        this.refreshDebounce = setTimeout(async () => {
          if(this.searchInput !== v.target.value) {
            this.searchInput = v.target.value;
            await this.refresh();
            setTimeout(() => {
              this.$refs.item.focus();
            }, 300);
          }
        }, 500);
      }
    },

    select(item) {
      if(item == null) return;
      this.$emit('input', item);
      this.$emit(`update:${this.itemKey}`, item[this.itemKey]);
      this.$emit(`update:${this.itemText}`, item[this.itemText]);
      if (this.emitAllProperties) {
        Object.keys(item).forEach(key => {
          if (key !== this.itemKey && key !== this.itemText) {
            this.$emit(`update:${key}`, item[key]);
          }
        });
      }
    },


    search(id = null) {
      this.page = 1;
      this.refresh(id);
    },
    async refresh(id = null) {
      this.loading = true;
      try {
        this.error = false;
        const page = await this.loaderFn(this.page, this.searchInput, id);
        this.elements = page.elements;
        this.page = page.currentPage;
      } catch (err) {
        this.error = true;
        //console.log(err);
      } finally {
        this.loading = false;
      }
    }
  },
  mounted() {
    this.refresh();
  }
}
</script>

<style scoped lang="scss">
::v-deep {

  .v-input__append-inner {
    cursor: pointer;
  }

  .v-input__append-outer {
    margin-left: 0;
    padding: 0 9px;
    border-bottom: thin solid rgba(0, 0, 0, 0.42);
    margin-top: -10px;
    //height: 29px;

  }
}
</style>
